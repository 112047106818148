<template>
  <div id="questionnaire">
    <div class="d-flex justify-space-between">
      <text-btn
        small
        icon="mdi-arrow-left"
        @click="$emit('tab-selected', 'Main')"
        >Вернуться на главную</text-btn
      >
    </div>
    <h3 class="text-center mb-7">
      {{ questionnaire.title }}
    </h3>
    <p v-if="questionnaire.introStatement" class="mb-5">
      {{ questionnaire.introStatement }}
    </p>
    <v-form v-if="questionnaire.questions">
      <div
        v-for="questionItem in questionnaire.questions"
        :key="questionItem.indexNumber"
        class="mb-3"
      >
        <v-subheader
          class="pl-0"
          style="font-size: 15px; font-weight: 500; color: black; height: auto"
        >
          {{ questionItem.indexNumber }}. {{ questionItem.question }}
        </v-subheader>
        <v-textarea
          v-if="questionItem.type === 'OPEN'"
          class="mt-3"
          hide-details
          outlined
          label="Введите текст"
          v-model="answersObj[questionItem.id]"
          height="250"
          no-resize
        />
        <v-slider
          v-if="questionItem.type === 'SCALE'"
          class="v-slider-labels--top mt-3"
          style="max-width: 400px"
          :tick-labels="tickLabels"
          tick-size="4"
          hide-details
          step="1"
          ticks="always"
          :max="10"
          :min="1"
          v-model="answersObj[questionItem.id]"
        />
        <v-radio-group
          v-if="questionItem.type === 'YES_NO'"
          class="mt-0"
          hide-details
          row
          v-model="answersObj[questionItem.id]"
        >
          <v-radio label="Да" value="Да"></v-radio>
          <v-radio label="Нет" value="Нет"></v-radio>
          <v-radio
            label="Затрудняюсь ответить"
            value="Затрудняюсь ответить"
          ></v-radio>
        </v-radio-group>
      </div>
      <p v-if="questionnaire.completionStatement" class="mb-5">
        {{ questionnaire.completionStatement }}
      </p>
      <confirm
        :extDialog="saveConfirm"
        text="Сохранить изменения?"
        @confirm="sendParticipantAnswers"
        @cancel="saveConfirm = false"
      >
        <v-btn class="primary ml-auto d-block" @click="saveConfirm = true"
          >Отправить ответы</v-btn
        >
      </confirm>
    </v-form>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, onMounted } from "@vue/composition-api";

export default {
  setup(_, { root, emit }) {
    const store = root.$store;

    const questionnaire = computed(
      () => store.getters.getParticipantQuestionnaire
    );

    const tickLabels = [...Array(10)].map((e, i) => (i + 1).toString());
    const answersObj = ref({});
    const saveConfirm = ref(false);

    onBeforeMount(async () => {
      await root.$store.dispatch("fetchParticipantQuestionnaire");
      questionnaire.value.questions.sort(
        (a, b) => a.indexNumber - b.indexNumber
      );
      createAnswersObj();
    });

    const createAnswersObj = () => {
      questionnaire.value.questions.forEach(q => {
        if (q.type === "YES_NO") {
          q.answer = "Да";
        }
        answersObj.value[q.id] = q.answer;
      });
    };

    const sendParticipantAnswers = () => {
      const answers = Object.keys(answersObj.value).map(id => {
        return {
          id,
          answer: answersObj.value[id].toString(),
        };
      });
      store.dispatch("sendParticipantAnswers", answers);

      emit("tab-selected", "Main");
    };

    onMounted(() => localStorage.setItem("lastTab", "Questionnaire"));

    return {
      questionnaire,
      tickLabels,
      answersObj,
      saveConfirm,
      createAnswersObj,
      sendParticipantAnswers,
    };
  },
};
</script>
